import {Component, HostListener, OnDestroy} from '@angular/core';
import { Store } from '@ngrx/store';
import { setIsMobile, setIsTablet } from "./shared/modules/ui/store/actions/ui.actions";
import {
  loadAllTranslations,
} from "./modules/settings/modules/translations/store/actions/translations.actions";
import { throttleMethod } from "./shared/helpers/decorators";
import { ViewUtils } from "./shared/helpers/viewUtils";
import { environment } from 'src/environments/environment';
import {AuthService} from "./modules/auth/store/services/auth.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  host: {
    '(window:resize)': 'onResize()'
  }
})
export class AppComponent implements OnDestroy {

  readonly googleApiKey = environment.google?.apiKey
  readonly googleScript =
    `https://maps.googleapis.com/maps/api/js?key=${this.googleApiKey}&libraries=visualization`

  constructor(private store$: Store, private authService: AuthService) {
    this.store$.dispatch(loadAllTranslations());
  }

  ngOnInit(): void {
    this.onResize();
  }

  @throttleMethod(100)
  onResize() {
    this.store$.dispatch(setIsMobile({ isMobile: ViewUtils.isMobileView() }));
    this.store$.dispatch(setIsTablet({ isTablet: ViewUtils.isTabletView() }));
  }

  @HostListener('window:beforeunload')
  ngOnDestroy() {
    this.authService.clearImpersonatedUserDataFromLocalStorage()
  }
}
